import { Box, Button, Typography } from '@mui/material';
import CongratsImage from '../../assets/images/congrats/congrats.webp';
import AppleLogo from '../../assets/svg/apple-logo.svg';
import GoogleLogo from '../../assets/svg/google-logo.svg';
import { signInWithApple, signInWithGoogle } from '@services/firebase';
import { APP_CONSTANTS } from '@utils/constants';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { isIos, isMetaInAppBrowser } from '@utils/browser';

function SignIn() {
  const showAppleSignIButton = isIos();

  const greetingMessage =
    !showAppleSignIButton && isMetaInAppBrowser
      ? 'It’s time to create your account and start your plan.\nPlease check your inbox for an email from us!'
      : 'Now create your account.\nChoose one of the listed Sign In options.';

  const { loading, error } = usePaymentState();

  return (
    <Box
      sx={{
        backgroundColor: '#FCF4EF',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          maxWidth: '460px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100vh',
          overflow: 'auto',
          margin: 'auto',
          padding: '0 20px',
          '& .congrats': {
            maxWidth: '209px',
            width: '100%',
            margin: '30px auto 0',
          },
        }}
      >
        <img
          draggable={false}
          src={CongratsImage}
          className="congrats"
          alt="congrats"
        />
        <Typography
          sx={{
            margin: '20px 0 0',
            fontFamily: 'Avenir600',
            fontSize: '24px',
            lineHeight: '32px',
            color: '#282624',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            '& span': {
              color: '#719c4c',
            },
          }}
        >
          {'Refuse\n'} <span>Successfully Purchased</span>
        </Typography>
        <Typography
          sx={{
            margin: '10px 0 0',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            color: '#282624',
          }}
        >
          {greetingMessage}
        </Typography>
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            width: '100%',
            gap: '10px',
            flexDirection: 'column',
            '& button.sign-in-button': {
              fontFamily: 'Avenir600',
              fontSize: '16px',
              lineHeight: '22px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              borderRadius: '12px',
              textTransform: 'none',
              padding: '14px',
              width: '100%',
            },
          }}
        >
          {showAppleSignIButton && (
            <>
              <Button
                disabled={loading}
                onClick={signInWithApple}
                className="sign-in-button"
                sx={{
                  background: '#282624',
                  color: 'white',
                  '&:hover': {
                    background: '#282624',
                  },
                }}
              >
                <img src={AppleLogo} alt="apple" /> Sign in with Apple
              </Button>
              {!isMetaInAppBrowser && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      width: '100%',
                      height: '1px',
                      background: 'rgba(240, 204, 185, 0.4)',
                    }}
                  />{' '}
                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#282624',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    or continue with
                  </Typography>
                  <Typography
                    sx={{
                      width: '100%',
                      height: '1px',
                      background: 'rgba(240, 204, 185, 0.4)',
                    }}
                  />
                </Box>
              )}
            </>
          )}

          {!isMetaInAppBrowser && (
            <Button
              disabled={loading}
              onClick={signInWithGoogle}
              className="sign-in-button"
              sx={{
                background: 'white',
                color: '#282624',
                '&:hover': {
                  background: 'white',
                },
              }}
            >
              <img src={GoogleLogo} alt="google" /> Google
            </Button>
          )}
        </Box>
        {error && (
          <Typography sx={{ margin: '20px 0 0 0', color: '#E03045' }}>
            {error}
          </Typography>
        )}
        <Box
          sx={{
            margin: 'auto 0 0',
            padding: '20px 0',
            borderTop: '1px solid rgba(240, 204, 185, 0.2)',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              fontSize: '14px',
              lineHeight: '19px',
              textAlign: 'center',
              color: '#282624',
              '& a': { color: '#ED744E', textDecoration: 'underline' },
            }}
          >
            Have a question? Reach our friendly support team{' '}
            <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>here</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SignIn;
