import { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import logo from '../../../assets/images/main/logo.svg';
import mail from '../../../assets/svg/mail.svg';
import mail_red from '../../../assets/svg/mail_red.svg';
import { Routes } from '../../../App';
import { EMAIL_REGEX } from '@utils/constants';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { preSaveUserData } from '@api/requests';

function Email({ onConfirm }: { onConfirm?: () => void }) {
  const navigate = useNavigate();

  const { quizData, setQuizData } = useQuizState();

  const [error, setError] = useState(false);

  const isDisabled = useMemo(() => {
    return quizData.email === '';
  }, [quizData.email]);

  const handleContinue = () => {
    const isValid = quizData.email.toLowerCase().match(EMAIL_REGEX);

    if (isValid) {
      preSaveUserData({
        email: quizData.email.trim(),
        answers: {
          name: quizData.name ?? '',
          age: quizData.age ?? '',
          intention: (quizData.areas ? quizData.areas.join(',') : '') as string,
          currentDrinkingHabits: (quizData.drinksAmount ?? '') as string,
          preferredDrinks: (quizData.prefferedDrinks ? quizData.prefferedDrinks.join(',') : '') as string,
          reasonForChange: (quizData.reason ?? '') as string,
          triggerSituations: (quizData.triggers ? quizData.triggers.join(',') : '') as string,
        },
      })
        .catch(console.error)
        .finally(() => {
          navigate(getPathWithSearchParams(Routes.ADVANTAGES));
        });
    } else {
      setError(true);
    }
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '17px',
          }}
        >
          <img width={'26px'} height={'26px'} src={logo} alt="logo"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '14px',
              userSelect: 'none',
              color: 'rgba(40, 38, 36, 1)',
            }}
          >
            Refuse
          </Typography>
        </Box>
        <Box
          sx={{
            padding: '40px 0',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontWeight: 600,
              fontSize: '24px',
              userSelect: 'none',
              textAlign: 'center',
              color: 'rgba(40, 38, 36, 1)',
            }}
          >
            Let's set up your account to save your progress and continue your
            journey!
          </Typography>
          <Box
            sx={{
              // padding: '0 20px',
              position: 'relative',
              '& img': {
                position: 'absolute',
                top: '22px',
                left: '20px',
              },
              '& input': {
                boxSizing: 'border-box',
                width: '100%',
                background: 'white',
                border: '1px solid rgba(240, 204, 185, 1)',
                borderRadius: '12px',
                color: 'rgba(40, 38, 36, 1)',
                fontFamily: 'Avenir500',
                fontSize: '16px',
                height: '60px',
                paddingLeft: '60px',
                '&:focus-visible': {
                  outline: 'none',
                },
              },
              '& input::placeholder': {
                color: 'rgba(144, 140, 137, 1)',
                fontFamily: 'Avenir400',
                fontSize: '16px',
                lineHeight: '22px',
              },
            }}
          >
            <img src={error ? mail_red : mail} alt="mail"></img>
            <input
              placeholder="Enter your email"
              type="email"
              value={quizData.email}
              onChange={(e) => {
                setError(false);
                setQuizData((prev) => ({ ...prev, email: e.target.value }));
              }}
            ></input>
            {error && (
              <Typography
                sx={{
                  color: '#E03045',
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                Please enter a valid email
              </Typography>
            )}
          </Box>

          <Typography
            sx={{
              color: 'rgba(144, 140, 137, 1)',
              fontFamily: 'Avenir400',
              fontSize: '14px',
              textAlign: 'center',
              userSelect: 'none',
              '& .link': {
                color: '#fff',
                textDecorationColor: '#fff',
              },
            }}
          >
            Your email is solely used to send insights and track your progress.
            Your privacy is our concern, and we never share your data to any
            third-party services.
          </Typography>
          <Box marginBottom={'30px'}>
            <QuizButton
              disabled={isDisabled || error}
              onClick={handleContinue}
              variant={ButtonType.ORANGE}
              text="Submit"
            ></QuizButton>
          </Box>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Email;
