import {
  useState,
  ReactNode,
  createContext,
  FC,
  useEffect,
  useRef,
  FormEvent,
} from 'react';

import { PaymentContextType, PaymentPlanType } from './PaymentProvider.types';
import { useRecurly } from '@recurly/react-recurly';

import { ApplePayError, ApplePayInstance } from '@recurly/recurly-js';
import {
  activateSubscriptionRecurly,
  createRecoveryGuide,
  createSubscriptionNoUser,
  finalizeSubscriptionRecurly,
  trackEvent,
} from '@api/requests';
import { Routes } from '../../App';
import { useQuizState } from '../QuizProvider/QuizProvider.hooks';
import { PaymentMethodType } from '../QuizProvider/QuizProvider.types';
import { useNavigate } from 'react-router-dom';
import { getParamByKey } from '@utils/common';
import { getPathWithSearchParams } from '@utils/ulr';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useUser } from '../UserProvider';
import {
  getParamsFromSessionStorage,
  setParamsToSessionStorage,
} from '@utils/storage';
import {
  getActiveSubscriptionConfig,
  removeRedirectParamsFromQuery,
  redirectBackAfterPayment,
} from '@utils/payment';
import { convertStringToBoolean, getAttribution } from '@utils/attribution';
import {
  isActiveSubscribeRecurlySuccessResponse,
  isSubscribeRecurlyErrorResponse,
  isSubscribeRecurlySecure3dResponse,
  isSubscribeRecurlySuccessResponse,
} from '@models/recurly';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { PixelEvents } from '@constants/events';
import { firebaseAuth } from '@services/firebase';

export const PaymentContext = createContext<PaymentContextType | null>(null);

type PlansToFinalize = Array<{ plan_id: string; subscription_id: string }>;

export const PaymentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const trickEvent = convertStringToBoolean(
    getParamByKey('event_trick') ?? 'false'
  );

  const trickFinalValue = trickEvent
    ? PixelEvents.Subscribe
    : PixelEvents.Purchase;

  const { setQuizData, quizData, userLocation } = useQuizState();

  const { paymentConfig, selectedPlan } = usePaymentConfig();

  const [isReadyAppleButton, setReadyAppleButton] = useState(false);
  const [applePay, setApplePay] = useState<ApplePayInstance | null>(null);
  const recurly = useRecurly();
  const formRef = useRef<HTMLFormElement>(null);
  const [paymentFormError, setPaymentFormError] = useState<string | null>(null);
  const [applePayError, setApplePayError] = useState<ApplePayError | null>(
    null
  );

  const [error, setError] = useState<null | string>(null);

  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const [subscriptionUserEmail, setSubscriptionUserEmail] = useState<
    string | null
  >(null);

  const [loading, setLoading] = useState(false);

  const [isAdditionalPaymentLoading, setAdditionalPaymentLoading] =
    useState(false);

  const [additionalPaymentError, setAdditionalPaymentError] = useState('');

  const recurlySecureRef = useRef(null);

  const navigate = useNavigate();

  const { user } = useUser();

  const activateSubscriptionConfig = getActiveSubscriptionConfig();

  const [additionalPlansToFinalize, setAdditionalPlansToFinalize] =
    useState<PlansToFinalize>([]);

  const redirectEmailParameter = getParamByKey('redirectUserEmail');

  const redirectUserEmail =
    redirectEmailParameter !== '' && redirectEmailParameter !== null
      ? window.decodeURIComponent(redirectEmailParameter)
      : null;

  useEffect(() => {
    if (selectedPlan) {
      setApplePay(
        recurly.ApplePay({
          country: 'US',
          currency: 'USD',
          label: selectedPlan.name_full ?? '',
          total: String(selectedPlan?.trial_sum ?? selectedPlan?.sum),
        })
      );
    }
  }, [selectedPlan]);

  useEffect(() => {
    applePay?.ready(() => {
      setReadyAppleButton(true);
      setQuizData((prev) => ({
        ...prev,
        selectedPaymentMethod: PaymentMethodType.APPLE,
      }));
    });
  }, [applePay]);

  useEffect(() => {
    if (applePay && subscriptionUserEmail !== null) {
      applePay.on('error', function (err) {
        console.error('Apple Pay error', err);
        setApplePayError(err);
        setPaymentLoading(false);
      });

      applePay.on('token', function (token) {
        if (!token) {
          console.error('token is not defined');
        } else {
          createSubscriptionNoUser({
            url: String(
              paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI
            ),
            email: subscriptionUserEmail,
            plan_id: selectedPlan?.id,
            token_id: token.id,
            attribution: getAttribution({
              ip: userLocation?.ip ?? '',
              country: userLocation?.country ?? '',
            }),
          })
            .then((response) => {
              if (isSubscribeRecurlyErrorResponse(response)) {
                console.error(response.error);
                setApplePayError({ message: response.error } as ApplePayError);
                setPaymentLoading(false);
              } else if (!isSubscribeRecurlySuccessResponse(response)) {
                console.error(response);
              } else {
                // @ts-ignore
                if (window.fbq) {
                  // @ts-ignore
                  fbq('track', 'Purchase');
                }

                if (trickFinalValue) {
                  trackEvent(trickFinalValue, userLocation).catch(
                    console.error
                  );
                }

                redirectBackAfterPayment({
                  recurlyTokenId: token.id,
                  subscriptionId: response.id,
                  email: subscriptionUserEmail,
                  planId: selectedPlan?.id ?? '',
                  recurlyUri: paymentConfig?.recurly?.uri ?? '',
                  accountCode: response.account.code,
                });
              }
            })
            .catch((response) => {
              if (typeof response.error === 'string') {
                setApplePayError(response.error);
              }
            })
            .finally(() => {
              setPaymentLoading(false);
            });
        }
      });
    }
  }, [applePay, subscriptionUserEmail, paymentConfig?.recurly?.uri]);

  useUpdateEffect(() => {
    const appleToken = user?.isAppleAuth
      ? user?.providerDataId ?? user?.uid
      : user?.uid;

    const sessionPaymentParams = getParamsFromSessionStorage([
      'accountCode',
      'subscriptionId',
      'recurlyTokenId',
      'selectedPlanId',
      'url',
      'additionalPlansToFinalize',
      'userEmail',
    ]);

    let recurlyUri = '';

    const redirectPlanId = getParamByKey('redirectPlanId');
    const redirectSubscriptionId = getParamByKey('redirectSubscriptionId');
    const redirectRecurlyUri = getParamByKey('redirectRecurlyUri');

    if (sessionPaymentParams.url) {
      recurlyUri = sessionPaymentParams.url;
    } else if (redirectRecurlyUri) {
      recurlyUri = redirectRecurlyUri;
    } else {
      recurlyUri =
        paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI ?? '';
    }

    const selectedPlanId =
      redirectPlanId ?? sessionPaymentParams.selectedPlanId;
    const sunscriptionId =
      redirectSubscriptionId ?? sessionPaymentParams.subscriptionId;

    const plansToFinalize: PlansToFinalize =
      sessionPaymentParams.additionalPlansToFinalize
        ? JSON.parse(sessionPaymentParams.additionalPlansToFinalize)
        : [];

    if (activateSubscriptionConfig && user) {
      setLoading(true);
      setError(null);

      activateSubscriptionRecurly({
        url: recurlyUri,
        email: user?.email ?? quizData.email,
        firebase_id: !user?.isAppleAuth ? appleToken : undefined,
        apple_token: user?.isAppleAuth ? appleToken : undefined,
        account_code: activateSubscriptionConfig.account_code,
      })
        .then(async (response) => {
          if (isActiveSubscribeRecurlySuccessResponse(response)) {
            navigate(getPathWithSearchParams(Routes.DOWNLOAD_APP));
          } else if (isSubscribeRecurlyErrorResponse(response)) {
            console.error(response);
            setError(response.error);
          } else {
            setError('Something went wrong');
          }
        })
        .catch((err) => {
          console.error(err);

          if (isSubscribeRecurlyErrorResponse(err)) {
            setError(err.error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (user && sunscriptionId !== '') {
      setLoading(true);
      setError(null);

      finalizeSubscriptionRecurly({
        url: recurlyUri,
        plan_id: selectedPlanId,
        subscription_id: sunscriptionId,
        email: user.email,
        firebase_id: !user?.isAppleAuth ? appleToken : undefined,
        apple_token: user?.isAppleAuth ? appleToken : undefined,
        attribution: getAttribution({
          ip: userLocation?.ip ?? '',
          country: userLocation?.country ?? '',
        }),
      })
        .then(async (response) => {
          if (isSubscribeRecurlySecure3dResponse(response)) {
            /** only for cards **/
            const risk = recurly.Risk();

            const threeDSecure = risk.ThreeDSecure({
              actionTokenId: response.actionTokenId,
            });

            threeDSecure.on('token', function (token) {
              createSubscriptionWithCardToken(token.id).then(() => {
                setPaymentLoading(false);
              });
            });

            threeDSecure.on('error', function (error) {
              console.error('error', error);
            });

            // @ts-ignore
            threeDSecure.attach(recurlySecureRef.current);

            return;
          } else if (isSubscribeRecurlySuccessResponse(response)) {
            if (plansToFinalize.length > 0) {
              let recoveryGuideFounded = false;

              plansToFinalize.forEach((planToFinalize) => {
                const recoveryGuidePlanIds = [
                  'rfs_ultimate_pack_otp',
                  'rfs_guide_otp',
                  'rfs_guide_sale_otp',
                ];

                if (recoveryGuidePlanIds.includes(planToFinalize.plan_id)) {
                  recoveryGuideFounded = true;
                }

                finalizeSubscriptionRecurly({
                  url: recurlyUri,
                  plan_id: planToFinalize.plan_id,
                  subscription_id: planToFinalize.subscription_id,
                  email: user.email,
                  firebase_id: !user?.isAppleAuth ? appleToken : undefined,
                  apple_token: user?.isAppleAuth ? appleToken : undefined,
                  attribution: getAttribution({
                    ip: userLocation?.ip ?? '',
                    country: userLocation?.country ?? '',
                  }),
                }).catch(console.error);
              });

              if (firebaseAuth.currentUser && recoveryGuideFounded) {
                firebaseAuth.currentUser
                  .getIdToken(true)
                  .then((token) => {
                    createRecoveryGuide({
                      user_id: user.uid,
                      email: redirectUserEmail ?? sessionPaymentParams.userEmail,
                      idToken: token,
                    }).catch(console.error);
                  })
                  .catch(console.error);
              }
            }

            setParamsToSessionStorage({
              accountCode: '',
              subscriptionId: '',
              recurlyTokenId: '',
              selectedPlanId: '',
              userEmail: '',
              url: '',
            });

            navigate(getPathWithSearchParams(Routes.DOWNLOAD_APP));
          } else if (isSubscribeRecurlyErrorResponse(response)) {
            console.error(response.error);
            setError(response.error);
          }
        })
        .catch((err) => {
          if (isSubscribeRecurlyErrorResponse(err)) {
            setError(err.error);
          }
        })
        .finally(() => {
          setLoading(false);
          removeRedirectParamsFromQuery();
        });
    }
  }, [user]);

  function handlePayByApple() {
    if (applePay) {
      setSubscriptionUserEmail(quizData.email);
      applePay.begin(() => {
        setPaymentLoading(true);
      });
    }
  }

  const createSubscriptionWithCardToken = (tokenId: string) =>
    selectedPlan
      ? createSubscriptionNoUser({
          url: String(
            paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI
          ),
          email: quizData.email,
          plan_id: selectedPlan?.id,
          token_id: tokenId,
          attribution: getAttribution({
            ip: userLocation?.ip ?? '',
            country: userLocation?.country ?? '',
          }),
        })
          .then((response) => {
            if (isSubscribeRecurlyErrorResponse(response)) {
              console.error(response.error);
              setPaymentFormError(response.error);
              setPaymentLoading(false);
            } else if (!isSubscribeRecurlySuccessResponse(response)) {
              console.error(response);
            } else {
              // @ts-ignore
              if (window.fbq) {
                // @ts-ignore
                fbq('track', 'Purchase');
              }

              if (trickFinalValue) {
                trackEvent(trickFinalValue, userLocation).catch(console.error);
              }

              setParamsToSessionStorage({
                url: paymentConfig?.recurly?.uri ?? null,
                selectedPlanId: selectedPlan.id,
                subscriptionId: response.id,
                recurlyTokenId: tokenId,
                accountCode: response.account.code,
                userEmail: quizData.email,
                attribution: JSON.stringify(
                  getAttribution({
                    country: userLocation?.country ?? '',
                    ip: userLocation?.ip ?? '',
                  })
                ),
              });

              if (getParamByKey('redirectDomain')) {
                redirectBackAfterPayment({
                  recurlyTokenId: tokenId,
                  subscriptionId: response.id,
                  email: quizData.email,
                  planId: selectedPlan?.id ?? '',
                  recurlyUri: paymentConfig?.recurly?.uri ?? '',
                  accountCode: response.account.code,
                });
              } else {
                navigate(getPathWithSearchParams(Routes.OFFERS));
              }
            }
          })
          .catch((response) => {
            if (typeof response.error === 'string') {
              setPaymentFormError(response.error);
            }
          })
      : Promise.resolve();

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (formRef.current) {
      setPaymentLoading(true);
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          console.error(err);
          setPaymentFormError(err.message);
          setPaymentLoading(false);
        } else {
          createSubscriptionWithCardToken(token.id).then(() => {
            setPaymentLoading(false);
          });
        }
      });
    }
  }

  const handleAdditionalOfferAdd = (
    additionalPlan: PaymentPlanType,
    onSuccess: () => void
  ) => {
    setAdditionalPaymentError('');
    setAdditionalPaymentLoading(true);

    // ?plan=rfs_weekly_trial3
    // &ult_pack=rfs_ultimate_pack_otp
    // &recovery_guide=rfs_guide_otp
    // &recovery_guide_s=rfs_guide_sale_otp
    // &wellness_program=rfs_program_otp
    // &wellness_program_s=rfs_program_sale_otp
    // &ai_coach=rfs_ai_coach_sale_otp

    const redirectRecurlyTokenId = getParamByKey('redirectRecurlyTokenId');
    const redirectAccountCode = getParamByKey('redirectAccountCode');

    const sessionPaymentParams = getParamsFromSessionStorage([
      'recurlyTokenId',
      'accountCode',
      'userEmail',
    ]);

    const body = {
      url: String(
        paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI
      ),
      email: redirectUserEmail ?? sessionPaymentParams.userEmail,
      plan_id: additionalPlan.id,
      token_id: redirectRecurlyTokenId ?? sessionPaymentParams.recurlyTokenId,
      account: redirectAccountCode ?? sessionPaymentParams.accountCode,
      attribution: getAttribution({
        ip: userLocation?.ip ?? '',
        country: userLocation?.country ?? '',
      }),
    };

    createSubscriptionNoUser(body)
      .then((response) => {
        if (isSubscribeRecurlyErrorResponse(response)) {
          setAdditionalPaymentError(response.error);
        } else if (!isSubscribeRecurlySuccessResponse(response)) {
          console.error(response);
        } else {
          trackEvent(`Purchase ${additionalPlan.name}`, userLocation).catch(
            console.error
          );
          setAdditionalPlansToFinalize((prev) => {
            const result = [
              ...prev,
              {
                plan_id: additionalPlan.id,
                subscription_id: response.id,
              },
            ];
            setParamsToSessionStorage({
              additionalPlansToFinalize: JSON.stringify(result),
            });

            return result;
          });
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          'error' in error ? error.error : JSON.stringify(error);
        trackEvent(`Purchase error ${additionalPlan.name}`, userLocation).catch(
          console.error
        );
        setAdditionalPaymentError(errorMessage);
      })
      .finally(() => {
        setAdditionalPaymentLoading(false);
      });
  };

  const value: PaymentContextType = {
    isReadyAppleButton,
    handlePayByApple,
    handleSubmitForm,
    paymentFormError,
    applePayError,
    formRef,
    isPaymentLoading,
    loading,
    paymentConfig,
    error,
    isAdditionalPaymentLoading,
    setAdditionalPaymentLoading,
    additionalPaymentError,
    setAdditionalPaymentError,
    handleAdditionalOfferAdd,
  };

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};
