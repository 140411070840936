import { Routes } from '../App';

export const redirectToPaymentPage = (email: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set('redirectDomain', window.location.origin);
  params.set('redirectUserEmail', email);

  const redirectPaymentDomain = () => {
    switch (true) {
      case (window.location.host.includes('dev.')): {
        return 'https://palmistry-dev.devroqapps.com'
      }
      case (window.location.host.includes('stg.')): {
        return 'https://palmistry-stg.devroqapps.com'
      }
      default: {
        return 'https://palmistry.devroqapps.com'
      }
    }
  }

  window.open(
    `${redirectPaymentDomain()}${Routes.SUBSCRIPTION}?${params}`,
    '_self'
  );
};

export function getActiveSubscriptionConfig() {
  const pathAsArray = window.location.pathname.split('/').filter(Boolean);

  if (pathAsArray.includes('activate-me') || pathAsArray.includes('activate')) {
    return {
      account_code: pathAsArray[pathAsArray.length - 1],
    };
  } else {
    return null;
  }
}

export function removeRedirectParamsFromQuery() {
  const searchParams = new URLSearchParams(window.location.search);

  [
    'redirectRecurlyTokenId',
    'redirectSubscriptionId',
    'redirectUserEmail',
    'redirectPlanId',
    'redirectRecurlyUri',
    'redirectAccountCode',
  ].forEach((param) => {
    searchParams.delete(param);
  });

  const newUrl = `${window.location.pathname}${searchParams.toString() ? '?' + searchParams : ''}${window.location.hash}`;

  window.history.replaceState(null, '', newUrl);
}

export const redirectBackAfterPayment = (
  {
    recurlyTokenId,
    subscriptionId,
    email,
    planId,
    recurlyUri,
    accountCode
  }: {
    recurlyTokenId: string,
    subscriptionId: string,
    email: string,
    planId: string,
    recurlyUri: string,
    accountCode: string,
  }
) => {
  const searchParams = new URLSearchParams(window.location.search);

  const redirectDomain = searchParams.get('redirectDomain');

  if (redirectDomain) {
    searchParams.delete('redirectDomain');
    searchParams.set('redirectRecurlyTokenId', recurlyTokenId);
    searchParams.set('redirectSubscriptionId', subscriptionId);
    searchParams.set('redirectUserEmail', email);
    searchParams.set('redirectPlanId', planId);
    searchParams.set('redirectRecurlyUri', recurlyUri);
    searchParams.set('redirectAccountCode', accountCode);

    const redirectUrl = `${redirectDomain}${Routes.OFFERS}?${searchParams}`;

    window.open(redirectUrl, '_self');
  }
};

export const getPriceWithDiscount = (price: number, discountPercent: number) => {
  return Number((price * 100) / (100 - discountPercent)).toFixed(2);
}
